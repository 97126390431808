import { render, staticRenderFns } from "./horizontalItem.vue?vue&type=template&id=79dcb1b2"
import script from "./horizontalItem.vue?vue&type=script&lang=js"
export * from "./horizontalItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports